import React from "react";
// import monkey from '../../assets/images/apesrunmonkey.svg'
import monkey from "../../../assets/images/apesrunmonkey.svg";

import primelogo from "../../../assets/images/primelogo.svg";

import "../Monkey/Monkey.scss";
import Web3 from "web3";
import Counter from "../../Counter";
const Monkey = ({ props }) => {
  function Convert_To_eth(val) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://polygon-mainnet.public.blastapi.io"
      )
    );

    val = web3.utils.fromWei(val.toString(), "ether");
    return val;
  }

  function Convert_To_Wei(val) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://polygon-mainnet.public.blastapi.io"
      )
    );

    val = web3.utils.toWei(val.toString(), "ether");
    return val;
  }

  return (
    <div>
      <div className="img-div">
        <div className="banner-img-div">
          <img
            src={monkey}
            width={707}
            height={67}
            alt="monkey"
            title="attractive image for banner"
            className="monkey-img"
          />
        </div>
        <div className="live-div">
          <h4>GET NOW</h4>
          <p>WE ARE LIVE</p>
        </div>
        <div className="prime-div">
          <div>
            <img
              src={primelogo}
              width={56}
              height={48}
              alt="prime-logo"
              title="logo for prime users"
              className="monkey-log"
            />
          </div>
          <div>
            <h4>LORD APE</h4>
            <p>APES PRIME</p>
          </div>
        </div>
        <div className="stage-div">
          <div>
            <h4>Stage {Number(props?.curr_stage) + 1}</h4>
            <p className="stage-raised">
              Raised : ${" "}
              {props?.total_raised
                ? (Number(props?.total_raised) / 10 ** 18).toFixed(2)
                : 0}{" "}
            </p>
          </div>
          <div>
            <h4>
              $
              {Number(props?.NextStagePrice)
                ? Convert_To_eth(props?.NextStagePrice)
                : ""}
            </h4>
            <p>+12.45%</p>
          </div>
        </div>
        <div className="price-div">
          <h4>
            Next Price <span>Increase in</span>
          </h4>
        </div>
        <div className="timing-div">
          <Counter
            time={
              props?.curr_presale?.endTime
                ? Number(props?.curr_presale?.endTime)
                : 0
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Monkey;
