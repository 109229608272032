import React, { useEffect, useState } from "react";
import Button from "../Button";
import { FaAngleDown, FaArrowRight } from "react-icons/fa6";
import Header from "../header";
import Counter from "../Counter";
// import VideoPlayer from "../videoPlayer";
import { FaAngleUp } from "react-icons/fa";
import { PiCopyLight } from "react-icons/pi";
import VideoSlider from "../videoSlider";

import Decimal from "decimal.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CopyToClipboard,
  PartnerIcon,
  CycleIcon,
} from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";

import Web3 from "web3";
import {
  usdt_address,
  token_abi,
  presale_address,
  presale_abi,
} from "../../configs/Contracts";
import "../../components/hero/Hero.scss";

import { useWeb3Modal } from "@web3modal/wagmi/react";

import { useSwitchChain, useAccount, useDisconnect } from "wagmi";

import {
  useSimulateContract,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import { polygon, polygonAmoy } from "wagmi/chains";
import HeroPopup from "../../screens/HeroPopup";
import Monkey from "../Common/Monkey/Monkey";
import FormBanner from "../Common/Form/FormBanner";
import bannervideo from "../../components/hero/Videos/banner-video.mp4";

const Hero = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [choosed_statement, set_choosed_statement] = useState(0);

  const openModal = (val) => {
    set_choosed_statement(val);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const chainId =
    process.env.REACT_APP_ENV == "production" ? polygon.id : polygonAmoy.id;
  const [selectedCurrency, setSelectedCurrency] = useState("USDT");

  const link_notify = () => toast("Referral Link Copied!");

  const [payAmount, set_payAmount] = useState(0);
  const [receiveAmount, set_receiveAmount] = useState(0);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { open, close } = useWeb3Modal();
  const [count, set_count] = useState(0);
  const { address, isConnecting, isDisconnected } = useAccount();

  const [selectedButton, setSelectedButton] = useState(null);

  useEffect(() => {
    if (payAmount != "") {
      onPay(payAmount);
    }
  }, [selectedCurrency, selectedButton]);

  const notify = () => toast("Token Purchased Successfully!");

  const handleSelect = (currency) => {
    setSelectedCurrency(currency);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const getBorderColor = (currency) => {
    return selectedCurrency === currency
      ? "tw-border-[#00F0FF] tw-border-2"
      : "tw-border-[#F0C238]";
  };

  const handleBSelect = (button) => {
    setSelectedButton(button);
  };

  const getBBorderColor = (button) => {
    return selectedButton === button
      ? "tw-border-[#00F0FF] tw-border-2"
      : "tw-border-white";
  };

  const openPdfInNewTab = () => {
    const pdfUrl = require("../../assets/images/whitepaper.pdf");
    window.open(pdfUrl, "_blank");
  };

  const [openVideo, setOpenVideo] = useState(false);

  const [prog_percentage, set_prog_percentage] = useState(0);
  const [ref_add, set_ref] = useState(
    "0x0000000000000000000000000000000000000000"
  );

  const { switchChainAsync } = useSwitchChain();
  const { chainId: currentChainId } = useAccount();
  const {
    writeContractAsync,
    writeContract,
    data: hash,
    ...states
  } = useWriteContract();

  useEffect(() => {
    if (params.get("ref") != null) {
      set_ref(params.get("ref"));
    }

    setpercantage();
    onPay(0);
  }, [props.curr_presale.endTime]);

  function setpercantage() {
    set_prog_percentage(
      (Number(props.curr_presale.total_sold) /
        10 ** 18 /
        (Number(props.curr_presale.supply) / 10 ** 18)) *
        100
    );
  }

  async function buytoken1() {
    try {
      const tx = await writeContractAsync({
        abi: presale_abi,
        address: presale_address,
        functionName: "buy_token",
        args: [
          Convert_To_Wei(payAmount ? Number(payAmount) : 0),
          ref_add,
          selectedCurrency == "POL" ? "0" : "1",
        ],
        value:
          selectedCurrency == "POL"
            ? Convert_To_Wei(payAmount ? Number(payAmount) : "0")
            : 0,
      });

      set_count(1);
    } catch (err) {
      console.error(err);
    }
  }

  async function usdt_approval() {
    try {
      const tx = await writeContractAsync({
        abi: token_abi,
        address: usdt_address,
        args: [presale_address, payAmount ? Number(payAmount) * 10 ** 6 : "0"],
        functionName: "approve",
      });
    } catch (err) {
      console.error(err);
    }
  }

  function Convert_To_eth(val) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://polygon-mainnet.public.blastapi.io"
      )
    );

    val = web3.utils.fromWei(val.toString(), "ether");
    return val;
  }

  function Convert_To_Wei(val) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://polygon-mainnet.public.blastapi.io"
      )
    );

    val = web3.utils.toWei(val.toString(), "ether");
    return val;
  }

  function onPay(value) {
    if (value == "" || value == 0) {
      set_receiveAmount(0);
      // set_payAmount(0)

      return;
    }

    let price;
    if (selectedCurrency == "USDT" || selectedCurrency == "USDC") {
      price = Number(props.curr_presale.price) / 10 ** 18;
    } else {
      price = Number(props.perTokenIn_Matic) / 10 ** 18;
    }

    let dec_price = new Decimal(price);
    value = new Decimal(value);
    let temp = value.div(dec_price);

    set_receiveAmount(Number(temp).toFixed(2));
  }

  function onRecieve(value) {
    if (value == "" || value == 0) {
      set_payAmount(0);

      return;
    }
    let price;
    if (selectedCurrency == "USDT" || selectedCurrency == "USDC") {
      price = Number(props.curr_presale.price) / 10 ** 18;
    } else {
      price = Number(props.perTokenIn_Matic) / 10 ** 18;
    }

    let dec_price = new Decimal(price);
    value = new Decimal(value);
    let temp = dec_price.times(value);

    set_payAmount(Number(temp).toFixed(2));
  }

  async function buy_token(choosed_option) {
    if (isDisconnected) {
      alert("Kindly connect your wallet");
      return;
    }
    if (payAmount == "" || payAmount == "0") {
      alert("Kidly write the amount");
      return;
    }

    if (selectedCurrency == "POL") {
      if (Number(props.MATICBalance) < Number(Convert_To_Wei(payAmount))) {
        alert("You don't have enough Pol");
        return;
      }

      if (Number(props.minPurchase_matic) / 10 ** 18 > Number(payAmount)) {
        alert(
          "Minimum purchase is " +
            (Number(props.minPurchase_matic) / 10 ** 18).toFixed(1) +
            " Pol"
        );
        return;
      }

      if (chainId != currentChainId) {
        await switchChainAsync({ chainId });
        await buytoken1?.();
      } else {
        await buytoken1?.();
      }
    } else if (selectedCurrency == "USDT") {
      if (Number(props.USDTBalance) / 10 ** 6 < Number(payAmount)) {
        alert("You don't have enough USDT");
        return;
      }
      if (Number(props.min_purchase) / 10 ** 18 > Number(payAmount)) {
        alert(
          "Minimum purchase is " +
            Number(props.min_purchase) / 10 ** 18 +
            " USDT"
        );
        return;
      }
      if (chainId != currentChainId) {
        await switchChainAsync({ chainId });
        await usdt_approval?.();
      } else {
        await usdt_approval?.();
      }
    }
  }
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    if (isConfirmed) {
      // alert(count)
      if (count == 0) {
        // set_count(1)
        buytoken1();
      }
      if (count == 1) {
        set_count(0);
        notify();
        set_payAmount(0);
        set_receiveAmount(0);
        props.test();
      }
    }
  }, [isConfirmed]);

  return (
    <div className="  tw-bg-cover tw-relative tw-bg-center tw-w-full tw-h-auto">
      <Header />

      <HeroPopup
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        previous_earning={
          choosed_statement == 1
            ? props.previous_earning1
            : choosed_statement == 2
            ? props.previous_earning2
            : choosed_statement == 3
            ? props.previous_earning3
            : choosed_statement == 4
            ? props.previous_earning4
            : choosed_statement == 5
            ? props.previous_earning5
            : null
        }
        data={
          choosed_statement == 1
            ? props.l1_statement
            : choosed_statement == 2
            ? props.l2_statement
            : choosed_statement == 3
            ? props.l3_statement
            : choosed_statement == 4
            ? props.l4_statement
            : choosed_statement == 5
            ? props.l5_statement
            : null
        }
      />
      <div className="home-banner-video">
        <video className="bg-video" playsInline autoPlay loop muted>
          <source src={bannervideo} type="video/mp4" />
        </video>
        <div className="video-color-filter" />
        <div className="video-color-filter-1" />
        <div className="desktop-view">
          <div className="container  tw-relative tw-pt-6 ">
            <div className="row    g-5 tw-items-center">
              <div className="col-lg-6 col-md-12 tw-pt-10 tw-pl-5 ">
                <div className="bluegradient">
                  <h1 className=" tw-text-[#ffff]   md:tw-text-[64px] tw-text-3xl tw-pl-5 tw-leading-[2rem] md:tw-leading-[4rem]">
                    Shake
                    <br />
                    to Earn Crypto
                    <br />
                    <span className="gradient-text leading-snug">
                      {" "}
                      With Apesrun{" "}
                    </span>
                  </h1>
                  <p className=" tw-text-white sm:tw-text-start tw-text-left tw-text-[14px] md:tw-text-[16px] tw-font-thin tw-leading-6 md:tw-leading-7 tw-pt-4 tw-text-md t  tw-pl-5 tw-font-poppins">
                    Get ready to turn your phone into a fun, rewarding machine
                    with Apes Run! Our unique "Shake to Earn" model combines
                    physical activity with a gamified experience, letting you
                    earn tokens by simply shaking your phone. No app downloads
                    or complex setups—just join our bot on Telegram and start
                    shaking!
                  </p>
                </div>
                <div className=" tw-flex tw-gap-7 sm:tw-justify-start tw-justify-start tw-pl-5">
                  <Button
                    // style={{ backgroundColor:"#E5B766" }}
                    onClick={openPdfInNewTab}
                    label={"White Paper"}
                    className={" tw-mt-7"}
                  />
                  {openVideo === true ? (
                    <button
                      onClick={() => setOpenVideo(false)}
                      className={
                        "  tw-bg-transparent tw-px-[18px] tw-py-[11px] tw-gap-2 tw-rounded-[30px] tw-border-white tw-flex tw-items-center  tw-text-white tw-border  tw-mt-7"
                      }>
                      Go back
                    </button>
                  ) : (
                    <button
                      onClick={() => setOpenVideo(true)}
                      className={
                        "  tw-bg-transparent tw-px-[40px] tw-py-[11px] tw-gap-2 tw-rounded-[30px]  tw-border-white tw-flex tw-items-center  tw-text-white tw-border tw-mt-7"
                      }>
                      Get APS
                    </button>
                  )}
                </div>
                <div className="collect-raised-div">
                  <div className="view-div">
                    <h4>432K</h4>
                    <p>Collections</p>
                  </div>
                  <div className="view-div">
                    <h4>$1M+</h4>
                    <p>Raised</p>
                  </div>
                  <div className="view-div">
                    <h4>100K+</h4>
                    <p>Community</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                {openVideo === true ? (
                  <FormBanner props={props} />
                ) : (
                  // <img  width={550} src={require("../../assets/images/hero1.png")} />
                  // <VideoSlider/>
                  <Monkey props={props} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-view">
        <video className="bg-video" playsInline autoPlay loop muted>
          <source src={bannervideo} type="video/mp4" />
        </video>
        <div className="video-color-filter" />
        <div className="video-color-filter-1" />

        <div className="container  tw-relative tw-pt-6 ">
          <div className="row    g-5 tw-items-center">
            <div className="col-lg-6 col-md-12  tw-pl-5 ">
              <div className="bluegradient">
                <h1 className=" tw-text-[#ffff]   md:tw-text-[64px] tw-text-3xl tw-pl-5 tw-leading-[2rem] md:tw-leading-[4rem]">
                  Shake
                  <br />
                  to Earn Crypto
                  <br />
                  <span className="gradient-text leading-snug">
                    {" "}
                    With Apesrun{" "}
                  </span>
                </h1>
                <p className=" tw-text-white sm:tw-text-start tw-text-left tw-text-[14px] md:tw-text-[16px] tw-font-thin tw-leading-6 md:tw-leading-7 tw-pt-4 tw-text-md t  tw-pl-5 tw-font-poppins">
                  Get ready to turn your phone into a fun, rewarding machine
                  with Apes Run! Our unique "Shake to Earn" model combines
                  physical activity with a gamified experience, letting you earn
                  tokens by simply shaking your phone. No app downloads or
                  complex setups—just join our bot on Telegram and start
                  shaking!
                </p>
              </div>
              <div className=" tw-flex tw-gap-7 sm:tw-justify-start tw-justify-start tw-pl-5">
                <Button
                  // style={{ backgroundColor:"#E5B766" }}
                  onClick={openPdfInNewTab}
                  label={"White Paper"}
                  className={" tw-mt-7"}
                />
                {openVideo === true ? (
                  <button
                    onClick={() => setOpenVideo(false)}
                    className={
                      "  tw-bg-transparent tw-px-[18px] tw-py-[11px] tw-gap-2 tw-rounded-[30px] tw-border-white tw-flex tw-items-center  tw-text-white tw-border  tw-mt-7"
                    }>
                    Go back
                  </button>
                ) : (
                  <button
                    onClick={() => setOpenVideo(true)}
                    className={
                      "  tw-bg-transparent tw-px-[40px] tw-py-[11px] tw-gap-2 tw-rounded-[30px]  tw-border-white tw-flex tw-items-center  tw-text-white tw-border tw-mt-7"
                    }>
                    Get APS
                  </button>
                )}
              </div>
              <div className="collect-raised-div">
                <div className="view-div">
                  <h4>432K</h4>
                  <p>Collections</p>
                </div>
                <div className="view-div">
                  <h4>$1M+</h4>
                  <p>Raised</p>
                </div>
                <div className="view-div">
                  <h4>100K+</h4>
                  <p>Community</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              {openVideo === true ? (
                <FormBanner props={props} />
              ) : (
                // <img  width={550} src={require("../../assets/images/hero1.png")} />
                // <VideoSlider/>
                <Monkey props={props} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <ToastContainer />
    </div>
  );
};

export default Hero;
